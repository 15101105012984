.cs-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px;

  .content {
    display: flex;
    align-items: center;
    img {
      margin-right: 68px;
    }
    .cs-text {
      display: flex;
      flex-direction: column;
      .cst-1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        line-height: 60px;
        text-align: center;

        color: #090909;
        margin-bottom: 12px;
      }
      .cst-2 {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        text-align: center;

        color: #4f4f4f;
        margin-bottom: 12px;
      }
      .cst-3 {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 42px;
        text-align: center;

        color: #ff8d1d;
        margin-bottom: 26px;
      }
      .cst-4 {
        background: #FF6700;
        border-radius: 4px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
        padding: 12px 45px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
