@import '../../../assets/stylesheets/index.scss';
@import '../../../assets/stylesheets/mixins.scss';

.navbar-container {
  display: flex;
  background-color: #fff;
  width: calc(100vw - 305px);
  height: 57px;
  position: fixed;
  border-bottom: #e5e5e5 1px solid;
  z-index: 7;
  left: 305px;
}
.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin-top: 22px;
  /* border-bottom: #e5e5e5 1px solid; */
}
.navbar-logo img {
  width: 90px;
  height: 37px;
}
.nav-mid-left h5,
.navbar-right h5 {
  font-family: Resist Sans Display;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.2rem;
}

// user menu drop down
.user-menu-div{
  display: flex;
  padding: 15px;
  .ant-btn > span{
    margin-top: 15px;
  }
  p{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #000000;
    margin: 0px;
    margin-left: 5px;
  }
  img {
    height: 35px;
    width: 35px;
    border-radius: 17px;
  }
  span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #000000;
    margin-left: 5px;
  }
}

.user-menu{
  min-width: 300px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.user-menu-down{
  margin-top: 5px;
  padding: 10px;
  background: rgba(255, 251, 251, 0.74);
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  button{
    width: 100%;
    height: 35px;
    margin-top: 5px;
    background: #FF6700;
    border-radius: 5px;
    border-width: 0px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #FFFFFF;
  }
  p{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #000000;
    margin: 0px;
    margin-bottom: 3px;
  }
}

.topbar {
  padding: 0 2rem;
  min-height: 64px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;

  @media (max-width: $sm-max-width) {
    padding: 0 1.06rem;
  }
}

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  @include transition-slow;

  &::after {
    opacity: 0.5;
    color: #FF6700;
    position: absolute;
    top: 45%;
    right: 0;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow;
  }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-6;
    }

    .icon {
      color: $primary;
    }
  }
}

.icon {
  margin-right: 0.5rem;
  position: relative;
  bottom: -2px;
  font-size: 1.13rem;
  color: $gray-5;
  @include transition-slow;
}

.user-dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  span{
    margin-left: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1C1C1C;
  }

  &::after {
    opacity: 0.5;
    color: #FF6700;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow();
  }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-6;
    }
  }
}

.avatar {
  background-color: $gray-2 !important;
}

/* Toggle Switch */
.toggle-switch {
  display: flex;
  align-items: center;
}
.flex-basis-4{
  flex-basis: 40%;
}
.switch-wrapper > input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}
.switch-wrapper > input[type='checkbox'] + .switch {
  transform: translateX(5px);
  background: #aaa;
}
.switch-wrapper > input[type='checkbox']:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper > input[type='checkbox']:disabled + .switch {
  background: #ccc;
}
.switch-container-1 {
  display: flex;
  align-items: center;
}
.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.switch-wrapper > .switch {
  display: flex;
  align-items: center;
  color: #fff;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  width: 14px;
  z-index: 3;
}
.switch-wrapper.large > .switch > .switch-handle {
  height: 30px;
  width: 30px;
}
.switch-label {
  line-height: 2.5rem;
  margin-right: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #828282;
}
.switch-active{
  color: #198754 !important;
}
.switch-wrapper.large + .switch-label {
  line-height: 4rem;
  margin-left: 15px;
}
.switch-wrapper .active {
  background-color: #198754 !important;
}
/* Toggle */

.navbar-mid {
  display: flex;
  align-items: center;
  justify-content: space-between  ;
  width: 25%;
  padding-left: 43px;
  border-left: #e5e5e5 1px solid;
  @media (max-width: 426px) {
    border-left: 0;
  }
}
.wayapay-navbar {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 5px;
  width: 100%;
  @media (max-width: 426px) {
    border-right: 0;
  }
}
.nav-mid-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
}
.navbar-right {
  display: flex;
  align-items: center;
  padding-left: 43px;
}

.sidebar {
  width: 280px;
  height: 100vh;
  /* top: 70px; */
  position: fixed;
  left: 0;
  background-color: #fff;
  z-index: 6;
  border-right: 1px solid #e5e5e5;
  overflow-x: hidden;
  overflow-y: scroll;
}

.nav-title{
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-top: 15px;
  margin-left: 5px;
  min-width: 180px;
}

.sidebar-wrap {
  position: relative;
}

.sidebar-menu-items {
  list-style-type: none;
  margin-top: 55px;
  padding: 0;

  .sidebar-menu-li-active {
    background: rgba(255, 102, 52, 0.1);
  }
  .sidebar-menu-li:hover {
    background: rgba(255, 102, 52, 0.1);
  }
}

.sidebar-menu-items .list {
  display: grid;
  align-items: center;
  min-height: 50px;
  width: 100%;
  padding-left: 3rem;
  font-size: 18px;
  justify-content: flex-start;
}
.sidebar-menu-items img {
  margin-right: 10px;
}

.sidebar-menu-items .more-wrapper {
  display: grid;
  .more-inner {
    height: 40px;
    cursor: pointer;
    color:#858585;
    padding-left: 5rem;
  }
  .more-inner:hover {
    color: $primary-color;
  }
}

// .layout-container {
//   /* background-color: #e5e5e5; */
//   position: relative;
//   left: 305px;
//   width: calc(100vw - 305px);
//   /* top: 70px; */
//   /* padding-top: 10px;
//   padding-left: 30px;
//   padding-right: 15px;
//   padding-bottom: 60px; */
//   @media (max-width: 426px) {
//     overflow: hidden;
//   }
// }
