.table-wrap.border .styleTable {
  border: none !important;
}

.styleTable tbody tr .MuiTableCell-root {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: var(--solidGrey) !important;
}

[data-rbd-drag-handle-context-id='1'] {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 20px !important;
  /* letter-spacing: 2px !important; */
}

.edition-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MuiTypography-h6 {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}

.optionsTab {
  margin-top: 5px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.optionsTab h2 {
  margin: 0;
  color: var(--mainColor);
  font-size: 12px;
  cursor: pointer;
}

.MTableHeader-header-13 {
  background: rgba(231, 71, 44, 0.16) !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #e7472c !important;
}

tr.MuiTableRow-root.MuiTableRow-footer {
  background: rgba(231, 71, 44, 0.16) !important;
  color: #e7472c !important;
}
.MuiToolbar-regular {
  display: none !important;
}
