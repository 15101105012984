.wayagram-div {
  // margin-top: 74px;
  .dasboard-col-one {
    // position: fixed;
    border-right: #e5e5e5 1px solid !important;
    @media (max-width: 426px) {
      border-right: 0 !important;
    }
    .profile {
      margin-right: 10px;
    }
  }
  .carousel-wrapper {
    display: flex;
    background: #fff;
    height: 30vh;
  }
  .moment-card {
    display: flex;
    align-items: flex-end;
    width: 160px;
    height: 166px;
    overflow: hidden;
    color: black;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    .moment-inner {
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 10px; 
      .moment-img {
        margin-right: 0.8rem;
        border: 1px solid red;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        .img-inner {
          width: 100%;
          height: 100%;
        }
      }
      h6 {
        margin: 0;
        font-size: 10px;
        font-weight: bolder;
      }
    }
  }
  .moment-card:hover h1 {
    transform: scale(1.1);
  }
}
.search-container{
  ::placeholder, ::-webkit-input-placeholder{
    text-align: center;
    color: #828282;
  }
  .search-input{
    border-radius: 1.25rem !important;
    background-color: #F2F2F2 !important;
  }
}
.tab{
  .tab-menu{
    overflow-x: scroll;
    height: 50px;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
  }
  ::-webkit-scrollbar {
    height: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF66341A 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF6700
  }
}
.active-tab{
  background-color: #FF6700;
  color: #FFF0EB !important;
}
.waya-tabs{
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;
  .waya-tab{
    text-align: center;
    flex-basis: 50%;
    height: 70px;
  }
  .active-waya-tab{
    color: #FF6700;
    border-bottom: 1px solid #FF6700;
  }
}
.containers {
  position: relative;
  margin: auto;
  width: 740px;
  height: 250px;
  background-image: url('https://images.unsplash.com/photo-1567400358510-f027b3196d5b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
  .bottom-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
    background-color: #151728;
    .menu {
      position: relative;
      width: 100%;
      display: flex;
      padding-left: 200px;
      height: 100%;
      z-index: 2;
    }
    .menu-link {
      cursor: pointer;
      color: #5c5e6e;
      font-family: 'TT Commons';
      font-size: 18px;
      font-weight: 400;
      padding: 25px 20px;
      height: 100%;
      transition: 0.3s;
      &.active,
      &:hover {
        color: #ffffff;
        border-bottom: 3px solid #1488fa;
      }
    }
  }
  .profile {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    bottom: 16px;
    left: 24px;
    .name {
      color: #ffffff;
      margin-left: 24px;
      margin-bottom: 48px;
      font-size: 22px;
      font-family: 'TT Commons';
      font-weight: 500;
    }
    img {
      width: 135px;
      height: 135px;
      border-radius: 50%;
      object-fit: cover;
      border: 3px solid #fff;
    }
  }
}

.poll-component-container {
  background-color: gold;
}

.wayagram-group-top {
  font-family: 'Libre Baskerville' !important;
  .option-div {
    img {
      height: 25px;
    }
  }
}

.member-img-grp {
  position: relative;
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .img-2 {
    position: absolute;
    left: 30px;
  }
  .img-3 {
    position: absolute;
    left: 60px;
  }
}
.event-wrapper::-webkit-scrollbar {
  display: none;
}
.event-wrapper {
  display: flex;
  flex-flow: nowrap;
  overflow-x: auto;
  align-items: center;
  .inner {
    height: 312px;
    min-width: 312px;
    margin-right: 15px;

    .event {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      border: 1px solid #5c5e6e;
      height: 100%;

      .event-img {
        height: 45% !important;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .event-detail {
        height: 55%;
        padding: 10px;
        .date {
          font-family: 'Lato';
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: #27ae60;
        }
        .title {
          font-family: 'Libre Baskerville';
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #1c1c1c;
        }
        .text-1 {
          font-size: 13px;
        }

        .text-2 {
          font-size: 12px;
        }
        .interested {
          background: #f2f2f2;
          border-radius: 999px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 12px;
          color: #1c1c1c;
          padding: 5px 35px;
          width: 65%;
        }
        .interested2 {
          border-radius: 50%;
          background: #f2f2f2;
          width: 24px;
          height: 24px;
          font-size: 14px;
        }
      }
    }
  }
}
