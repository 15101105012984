.contents {
  width: calc(100% - 50px);
}
.TableHeaders {
  width: 100%;
}
.transactionAmount {
  width: 100%;
  margin: 30px 0px;
  min-height: 200px;
}
.dataTable {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.eachCount {
  width: 23%;
  margin-right: 10px;
}
.colGain {
  width: 100%;
}
.disputeTypes {
  margin-bottom: 30px;
}
